import { createAsyncThunk } from "@reduxjs/toolkit";
import functionAPI from "../../scripts/functionAPI";
// import authAPI from '../../scripts/authAPI';
// import { setToken } from '../auth/slices';
import keycloak from "../../keycloak";

const getToken = async (thunkAPI) => {
  if (keycloak) {
    return keycloak.token;
  }

  return null;
  /*
        let state = thunkAPI.getState();
        const refreshToken = await authAPI.refreshToken(state.auth.token, state.auth.username, state.auth.password);
        thunkAPI.dispatch(setToken(refreshToken));
      
        return refreshToken;
        */
};

const getUser = async (thunkAPI) => {
  if (keycloak) {
    return keycloak.loadUserInfo();
  }
}

export const callDeviceFunction = createAsyncThunk(
  "function/callDeviceFunction",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const user = await getUser(thunkAPI);
      const response = await functionAPI.callDeviceFunction(args.data, token);
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);
