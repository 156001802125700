import React, { lazy, useEffect } from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useKeycloak } from "@react-keycloak/web";

import routes from "./routes";
import { selectAuthFromState } from "./redux/auth/selectors";
import Loading from "./components/Loading";

const HomeScreen = lazy(() => import("./screens/HomeScreen"));  // import("./screens/HomeScreen"));
// const LoginScreen = lazy(() => import("./screens/LoginScreen"));
const EnclosureSummaryScreen = lazy(() => import("./screens/EnclosureSummaryScreen"));
const ScanningScreen = lazy(() => import("./screens/ScanningScreen"));
const SearchingScreen = lazy(() => import("./screens/SearchingScreen"));
const PostInstallScreen = lazy(() => import("./screens/PostInstallScreen"));
const DeviceTestScreen = lazy(() => import("./screens/DeviceTestScreen"));

const RouteStack = () => {
  const { keycloak, initialized } = useKeycloak();
  const { username } = useSelector(selectAuthFromState);

  useEffect(() => {
    if (initialized && keycloak) {
      if (!keycloak.authenticated) {
        keycloak.logout();
      }
    }
  }, [initialized, keycloak])

  const PrivateRoute = ({component: Component, ...rest}) => 
    <Route
      {...rest}
      render={props => username 
        ? <Component {...props} />
        : <Redirect to={{pathname: routes.LOGIN, state: {from: props.location}}} />}
    />;

  return (
    <BrowserRouter>
    {keycloak && keycloak.authenticated ? (
      <Switch>
        <Route
          path={routes.ENCLOSURE_SUMMARY_SCREEN + "/:docid"}
          component={EnclosureSummaryScreen}
        />
        <Route
          path={routes.DEVICE_TEST_SCREEN + "/:docid"}
          component={DeviceTestScreen}
        />
        <Route
          path={routes.POST_INSTALL_SCREEN + "/:docid"}
          component={PostInstallScreen}
        />
        <Route
          path={routes.SCANNING_SCREEN + "/:docid"}
          component={ScanningScreen}
        />
        <Route
          path={routes.SEARCHING_SCREEN + "/:docid/:number?"}
          component={SearchingScreen}
        />        
        <Route component={HomeScreen} />
      </Switch>
    ) : (
      <Loading />
    )}
    </BrowserRouter>
  );
};

export default RouteStack;

/*
    <BrowserRouter>
    {username ? (
      <Switch>
        <PrivateRoute exact path="/" component={HomeScreen} />
        <PrivateRoute path={routes.HOME_SCREEN} component={HomeScreen} />
        <PrivateRoute
          path={routes.ENCLOSURE_SUMMARY_SCREEN + "/:docid"}
          component={EnclosureSummaryScreen}
        />
        <PrivateRoute
          path={routes.DEVICE_TEST_SCREEN + "/:docid"}
          component={DeviceTestScreen}
        />
        <PrivateRoute
          path={routes.POST_INSTALL_SCREEN + "/:docid"}
          component={PostInstallScreen}
        />
        <PrivateRoute
          path={routes.SCANNING_SCREEN + "/:docid"}
          component={ScanningScreen}
        />
        <PrivateRoute
          path={routes.SEARCHING_SCREEN + "/:docid/:number?"}
          component={SearchingScreen}
        />        
      </Switch>
    ) : (
      <Route component={LoginScreen} />
    )}
    </BrowserRouter>
    */

