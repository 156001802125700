import { createSlice } from '@reduxjs/toolkit';
import { login } from '../api';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        username: null,
        password: null,
        token: null,
        dataToken: null,
        loading: false,
        error: null,
    },
    reducers: {
        logout(state) {
            state.username = null;
            state.password = null;
            state.token = null;
        },
        setToken(state, action) {
            state.token = action.payload;
        },
        setDataToken(state, action) {
            state.dataToken = action.payload;
        }
    },
    extraReducers: {
        [login.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.username = null;
            state.password = null;
            state.token = null;
        },
        [login.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },
        [login.fulfilled]: (state, action) => {
            state.token = action.payload.token;
            state.username = action.payload.username;
            state.password = action.payload.password;
            state.loading = false;
        }
    }
});

export const { logout, setToken, setDataToken } = authSlice.actions;
export default authSlice.reducer;