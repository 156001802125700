import { createStore } from "redux";
import reducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import localforage from "localforage";
import createCompressEncryptor from "redux-persist-transform-compress-encrypt";
import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

const transformer = createCompressEncryptor({
  secretKey: "B6gd45sRevtVkQ9bcT58",
  onError: (error) => {
    // fired whenever there is any issue with transformation,
    // compression or encryption/decryption
    console.log(error);
  },
});

const persistConfig = {
  key: "root",
  storage: localforage,
  transforms: [transformer],
  blacklist: ["filter"], // do not persist filter, users may forget they have set a value
};

const persistedReducer = persistReducer(persistConfig, reducer);

const logger = createLogger({
  //predicate: makeLogFilter('realtime/getRealtimePQ', 'realtime/getRealtimeUplink', 'realtime/streamRealtimeUplink', 'realtime/getRealtimeInst', 'realtime/streamRealtimeInst'),
  stateTransformer: (state) => state.filter,
});

// Redux: Store
const store = configureStore({
  reducer: persistedReducer,
  logger,
  middleware: [thunk],
});

// Redux Persist Persister
const persistor = persistStore(store);
// persistor.purge();

if (window.Cypress) {
  window.store = store;
}

// Exports
export { store, persistor };
