import { combineReducers } from "redux";

import authReducer from "./auth/slices";
import configReducer from "./config/slices";
import connectionReducer from "./connection/slices";
import dataReducer from "./data/slices";
import filterReducer from "./filter/slices";
import functionReducer from "./function/slices";

export default combineReducers({
  connection: connectionReducer,
  auth: authReducer,
  function: functionReducer,
  config: configReducer,
  filter: filterReducer,
  data: dataReducer,
});

/*
import { ACTION_SET_ADDRESS, ACTION_SET_POLE_NUMBER, ACTION_SET_INSTALL_NUMBER, ACTION_SET_CONNECTIONS, ACTION_SET_TOKEN, ACTION_SET_USERNAME, ACTION_LOGOUT, ACTION_FILTER } from "./actions";

const initialState = {
  poleNumber: "",
  address: "",
  installNumber: "",
  connections: [],
  filtered: [],
  username: null,
  token: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_SET_TOKEN: {
            return {
                ...state,
                token: action.payload
            }
        }

        case ACTION_SET_USERNAME: {
            return { 
                ...state,
                username: action.payload,
            }
        }

        case ACTION_LOGOUT: {
            return {
                ...state,
                username: null,
                token: null
            }
        }

        //setting one field clears the others 
        case ACTION_SET_ADDRESS: {
            return {
                ...state,
                address: action.payload,
                installNumber: "",
                poleNumber: ""
            };
        }

        case ACTION_SET_POLE_NUMBER: {
            return {
                ...state,
                poleNumber: action.payload,
                address: "",
                installNumber: ""
            };
        }

        case ACTION_SET_INSTALL_NUMBER: {
            return {
                ...state,
                installNumber: action.payload,
                address: "",
                poleNumber: ""
            }
        }

        case ACTION_SET_CONNECTIONS: {
            return {
                ...state,
                connections: action.payload
            }
        }

        case ACTION_FILTER: {
            const newFiltered = runFilter(state);
            return {
                ...state,
                filtered: newFiltered
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}


const runFilter = state => {
    if (state.connections) {
        let results = [...state.connections];
        if (state.address) {
            results = results.filter(item =>
                item.location.streetAddress1.toLowerCase().includes(state.address.toLowerCase())
            );
        }
        if (state.installNumber) {
            results = results.filter(item =>
                item.IDNumber.toLowerCase().startsWith(state.installNumber.toLowerCase())
            );
        }
        if (state.poleNumber) {
            results = results.filter(item => 
               item.mount.mountNumber && item.mount.mountNumber.startsWith(state.poleNumber)
            );
        }
        
        return results;
    } 
};

export default reducer;

*/
