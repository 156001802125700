import axios from 'axios';
import base64 from 'base-64';

import { getAuthServiceUrl } from './urlHelper';

const getOptions = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
        }
    };
};

const authAPI = {};

authAPI.getToken = async(username, password) => {
    const api = `${getAuthServiceUrl()}/token`;
    let data = {
        username,
        password
    };
    const response = await axios.post(api, data);
    return response.data;
};

authAPI.checkTokenExpiry = (token) => {
    if (!token) {
        return false;
    }
    try {
        var base64Url = token.split('.')[1];
        var b64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var decoded = base64.decode(b64);
        var json = JSON.parse(decoded);
    
        if (json.exp < Date.now() / 1000) {
            return false;
        }
    
        return true;
    } catch(err) {
        console.log(err);
        return false;
    }
   
};

authAPI.refreshToken = async(token, username, password) => {
    const valid = authAPI.checkTokenExpiry(token);
    if (!valid) {
        const newToken = await authAPI.getToken(username, password);
        return newToken;
    }
    return token;
};

authAPI.getDataToken = async(token) => {
    const api = `${getAuthServiceUrl()}/datatoken`;
    const response = await axios.get(api, getOptions(token));
    return response.data;
};

authAPI.refreshDataToken = async(datatoken, keycloaktoken) => {
    const valid = authAPI.checkTokenExpiry(datatoken);
    if (!valid) {
        const newToken = await authAPI.getDataToken(keycloaktoken);
        return newToken;
    }
    return datatoken;
};


export default authAPI;