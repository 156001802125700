import { createAsyncThunk } from "@reduxjs/toolkit";
import configAPI from "../../scripts/configAPI";
// import authAPI from '../../scripts/authAPI';
// import { setToken } from '../auth/slices';
import keycloak from "../../keycloak";

const getToken = async (thunkAPI) => {
  if (keycloak) {
    return keycloak.token;
  }

  return null;
  /*
      let state = thunkAPI.getState();
      const refreshToken = await authAPI.refreshToken(state.auth.token, state.auth.username, state.auth.password);
      thunkAPI.dispatch(setToken(refreshToken));
    
      return refreshToken;
      */
};

export const getConfigField = createAsyncThunk(
  "config/getConfigField",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await configAPI.getConfigField(
        args.deviceEUI,
        args.fieldName,
        token
      );
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const getCtprim = createAsyncThunk(
  "config/getCtprim",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await configAPI.getConfigField(
        args.deviceEUI,
        "ctprim",
        token
      );
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const setConfigField = createAsyncThunk(
  "config/setConfigField",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await configAPI.setConfigField(
        args.deviceEUI,
        args.fieldName,
        args.value,
        token
      );
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const applyRoffset = createAsyncThunk(
  "config/applyRoffset",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await configAPI.applyRoffset(args.deviceEUI, token);
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);
