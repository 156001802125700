import { createAsyncThunk } from "@reduxjs/toolkit";

import dataAPI from "../../scripts/dataAPI";
import authAPI from "../../scripts/authAPI";
import { setDataToken, setToken } from "../auth/slices";
import keycloak from "../../keycloak";

const getDataToken = async (thunkAPI) => {
  if (keycloak) {
    let state = thunkAPI.getState();
    let dataToken = state.auth.dataToken;
    if (!dataToken) {
      dataToken = await authAPI.getDataToken(keycloak.token);
      thunkAPI.dispatch(setDataToken(dataToken));
    }
    return dataToken;
  }

  return null;
  /*
    let state = thunkAPI.getState();
    const refreshToken = await authAPI.refreshToken(state.auth.token, state.auth.username, state.auth.password);
    thunkAPI.dispatch(setToken(refreshToken));
  
    return refreshToken;
    */
};

export const getPQMsgData = createAsyncThunk(
  "data/pq",
  async (args, thunkAPI) => {
    try {
      const dataToken = await getDataToken(thunkAPI);
      const response = await dataAPI.getPQMsgData(
        args.deviceEUI,
        args.sinceSeconds,
        dataToken
      );
      return response;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(setDataToken(null));
      throw Error(err.message);
    }
  }
);

export const getInstMsgData = createAsyncThunk(
  "data/inst",
  async (args, thunkAPI) => {
    try {
      const dataToken = await getDataToken(thunkAPI);
      const response = await dataAPI.getInstMsgData(
        args.deviceEUI,
        args.sinceSeconds,
        dataToken
      );
      return response;
    } catch (err) {
      thunkAPI.dispatch(setDataToken(null));
      throw Error(err.message);
    }
  }
);

export const getUplinkMsgData = createAsyncThunk(
  "data/uplink",
  async (args, thunkAPI) => {
    try {
      const dataToken = await getDataToken(thunkAPI);
      const response = await dataAPI.getUplinkMsgData(
        args.deviceEUI,
        args.sinceSeconds,
        dataToken
      );
      return response;
    } catch (err) {
      thunkAPI.dispatch(setDataToken(null));
      throw Error(err.message);
    }
  }
);
