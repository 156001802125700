import { createSlice } from "@reduxjs/toolkit";

import {
  createConnection,
  createImage,
  getConnections,
  deleteImage,
  getConnectionById,
  getDevice,
  getImage,
  updateConnection,
  updateJob,
} from "../api";

const connectionSlice = createSlice({
  name: "connection",
  initialState: {
    //poleNumber: '',
    // address: '',
    //installNumber: '',
    connections: [],
    connection: null,
    filtered: [],
    loading: false,
    error: null,
    device: null,
    image: null,
    response: null,
  },
  reducers: {
    setConnections(state, action) {
      state.connections = action.payload;
    },
    filterConnections(state, action) {
      state.filtered = runFilter(state.connections, action.payload);
    },
    getConnection(state, action) {
      state.connection = state.connections.find((c) => c.id === action.payload);
    },
  },
  extraReducers: {
    [getConnections.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getConnections.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getConnections.fulfilled]: (state, action) => {
      state.connections = action.payload;
      state.loading = false;
    },
    [getConnectionById.pending]: (state, action) => {
      state.loading = true;
      state.connection = null;
      state.error = null;
    },
    [getConnectionById.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getConnectionById.fulfilled]: (state, action) => {
      state.connection = action.payload;
      state.loading = false;
    },
    [getDevice.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.device = null;
    },
    [getDevice.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getDevice.fulfilled]: (state, action) => {
      state.device = action.payload;
      state.loading = false;
    },
    [createConnection.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.connection = null;
    },
    [createConnection.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [createConnection.fulfilled]: (state, action) => {
      state.connection = action.payload;
      state.loading = false;
    },
    [updateConnection.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.connection = null;
    },
    [updateConnection.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [updateConnection.fulfilled]: (state, action) => {
      state.connection = action.payload;
      state.loading = false;
    },
    [getImage.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.image = null;
    },
    [getImage.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getImage.fulfilled]: (state, action) => {
      state.image = action.payload;
      state.loading = false;
    },
    [createImage.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    },
    [createImage.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [createImage.fulfilled]: (state, action) => {
      state.response = action.payload;
      state.loading = false;
    },
    [deleteImage.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    },
    [deleteImage.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [deleteImage.fulfilled]: (state, action) => {
      state.response = action.payload;
      state.loading = false;
    },
    [updateJob.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [updateJob.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [updateJob.fulfilled]: (state, action) => {
      state.loading = false;
    },
  },
});

const runFilter = (connections, filter) => {
  let results = [...connections];
  if (filter.address) {
    results = results.filter(
      (item) =>
        item.location &&
        item.location.streetAddress1 &&
        item.location.streetAddress1
          .toLowerCase()
          .includes(filter.address.toLowerCase())
    );
  }
  if (filter.installNumber) {
    results = results.filter((item) =>
      item.IDNumber.toLowerCase().startsWith(filter.installNumber.toLowerCase())
    );
  }
  if (filter.poleNumber) {
    results = results.filter(
      (item) =>
        item.mount.mountNumber &&
        item.mount.mountNumber.startsWith(filter.poleNumber)
    );
  }

  return results;
};

export const {
  setAddress,
  setPoleNumber,
  setInstallNumber,
  setConnections,
  filterConnections,
  getConnection,
} = connectionSlice.actions;

export default connectionSlice.reducer;
