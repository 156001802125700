export default {
  AUTH_LOADING_SCREEN: "/authloading",
  HOME_SCREEN: "/home",
  MOUNT_DETAILS_SCREEN: "/mount-details",
  MOUNT_TYPE_SCREEN: "/mount-type",
  MOUNT_IDENTIFIER_SCREEN: "/mount-identifier",
  MOUNT_LOCATION_SCREEN: "/mount-location",
  //ENCLOSURE_TYPE_SCREEN: "/enclosure-type",
  ENCLOSURE_SUMMARY_SCREEN: "/enclosure-summary",
  ADD_JOB_SCREEN: "/add-job",
  DEVICE_TEST_SCREEN: "/device-test",
  SCANNING_SCREEN: "/scan",
  SEARCHING_SCREEN: "/search",
  POST_INSTALL_SCREEN: "/install-details"
};
