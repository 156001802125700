import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import keycloak from "./keycloak";
import axios from "axios";

import Loading from "./components/Loading";
import RouteStack from "./RouteStack";

const App = () => {
  //error reporting https://sentry.io/electronet-technology/react-installer-app
  /*Sentry.init({
    dsn: "https://a67171af920748c18855e5bc46e7d978@sentry.io/2475436"
  });*/

  useEffect(() => {
    checkVersion();
  }, []);

  const checkVersion = async () => {
    try {
      const cleared_cache = false;
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
            cleared_cache = true;
          }
        });
        // delete browser cache and hard reload
        if(cleared_cache)
          window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Suspense fallback={<Loading />}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onLoad: "login-required" }}
      >
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <RouteStack />
          </PersistGate>
        </Provider>
      </ReactKeycloakProvider>
    </Suspense>
  );
};

export default App;
