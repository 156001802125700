import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    poleNumber: "",
    address: "",
    installNumber: "",
  },
  reducers: {
    clear(state) {
      state.poleNumber = "";
      state.address = "";
      state.installNumber = "";
    },
    setAddress(state, action) {
      state.address = action.payload;
      state.error = null;
    },
    setPoleNumber(state, action) {
      state.poleNumber = action.payload;
    },
    setInstallNumber(state, action) {
      state.installNumber = action.payload;
    },
  },
});

export const {
  clear,
  setAddress,
  setPoleNumber,
  setInstallNumber,
} = filterSlice.actions;

export default filterSlice.reducer;
