import { createSlice } from '@reduxjs/toolkit';
import { 
  getPQMsgData,
  getInstMsgData,
  getUplinkMsgData,
} from "../api";

const initialState = {
  error: null,
  loading: null,
  phase1Voltage: null,
  phase1Current: null,
  phase1ActivePower: null,
  phase2Voltage: null,
  phase2Current: null,
  phase2ActivePower: null,
  phase3Voltage: null,
  phase3Current: null,
  phase3ActivePower: null,
  received: false,
  voltageError: null,
  rssi: null,
  snr: null,
  timestamp : null,
  timestampINST : null,
  timestampPQ : null,
  timestampUPLINK : null,
  instID : null,
  uplinkID : null,
  FCNT : null
};

const dataSlice = createSlice({
  name: 'data',
  initialState: initialState,
  reducers: {
    clearData(state, action) {
      state.instID = null;
      state.phase1Voltage = null;
      state.phase1Current = null;
      state.phase1ActivePower = null;
      state.phase2Voltage = null;
      state.phase2Current = null;
      state.phase2ActivePower = null;
      state.phase3Voltage = null;
      state.phase3Current = null;
      state.phase3ActivePower = null;
      
      state.rssi = null;
      state.snr = null;
      state.timestamp = null;
      state.timestampINST = null;
      state.timestampPQ = null;
      state.timestampUPLINK = null;
      state.received = false;
      state.receivedType = null;
      state.error = null;
      state.voltageError = null;
    },
  },
  extraReducers: {
    [getPQMsgData.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    [getPQMsgData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getPQMsgData.fulfilled]: (state, action) => {
      state.loading = false;
      mapPQData(action.payload, state);
    },
    [getInstMsgData.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    [getInstMsgData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getInstMsgData.fulfilled]: (state, action) => {
      state.loading = false;
      mapInstData(action.payload, state);
    },
    [getUplinkMsgData.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    [getUplinkMsgData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getUplinkMsgData.fulfilled]: (state, action) => {
      state.loading = false;
      mapUplinkData(action.payload, state);
    },
  }
});

const mapPQData = (data, state) => {
  if(data.length > 0 ){
    state.received = true;
    state.receivedType = 1;
    state.timestamp = new Date(data[0].TIMESTAMP);
    state.timestampPQ = new Date(data[0].TIMESTAMP);
  }
  data.forEach(item => { 
    if (item.PHASE == 1) {
      state.phase1Voltage = item.VOLTAGESMA;
      state.phase1Current = item.CURRENTSMA;
      state.phase1ActivePower = item.POWERACTIVESMA;
    } else if (item.PHASE == 2) {
      state.phase2Current = item.CURRENTSMA;
      state.phase2Voltage = item.VOLTAGESMA;
      state.phase2ActivePower = item.POWERACTIVESMA;
    } else if (item.PHASE == 3) {
      state.phase3Current = item.CURRENTSMA;
      state.phase3Voltage = item.VOLTAGESMA;
      state.phase3ActivePower = item.POWERACTIVESMA;
    }
  });
  if (data.length > 0 && !state.phase1Voltage && !state.phase2Voltage && !state.phase3Voltage) {
    state.voltageError = 'Zero voltage. Check MODBUS connection';
  } else {
    state.voltageError = null;
  }
}

const mapInstData = (data, state) => {
  if(data.length > 0 ){
    state.received = true;
    state.receivedType = 2;
    state.instID = data[0].ID;
    state.timestamp = new Date(data[0].TIMESTAMP);
    state.timestampINST = new Date(data[0].TIMESTAMP);
  }
  var phase1 = false;
  var phase2 = false;
  var phase3 = false;
  data.forEach(item => {
    if (!phase1 && item.PHASE == 1) {
      state.phase1Voltage = item.VOLTAGE;
      state.phase1Current = item.CURRENT;
      state.phase1ActivePower = item.ACTIVEPOWER;
      phase1 = true;
    } else if (!phase2 && item.PHASE == 2) {
      state.phase2Current = item.CURRENT;
      state.phase2Voltage = item.VOLTAGE;
      state.phase2ActivePower = item.ACTIVEPOWER;
      phase2 = true;
    } else if (!phase3 && item.PHASE == 3) {
      state.phase3Current = item.CURRENT;
      state.phase3Voltage = item.VOLTAGE;
      state.phase3ActivePower = item.ACTIVEPOWER;
      phase3 = true;
    }
  });
  if (data.length > 0 && !state.phase1Voltage && !state.phase2Voltage && !state.phase3Voltage) {    
    state.voltageError = 'Zero voltage. Check MODBUS connection';
  } else {
    state.voltageError = null;
  }
}

const mapUplinkData = (data, state) => {
  if (data.length > 0 && data[0]) {
    state.snr = data[0].SNR;
    state.rssi = data[0].RSSI;
    state.receivedType = 0;
    state.received = true;
    state.timestamp = new Date(data[0].TIMESTAMP);
    state.timestampUPLINK = new Date(data[0].TIMESTAMP);
    state.uplinkID = data[0].ID;
    state.FCNT = data[0].FCNT;
  }
  else
  {
    state.rssi = null;
    state.snr = null;
    state.timestamp = null;
    state.timestampINST = null;
    state.timestampPQ = null;
    state.timestampUPLINK = null;
    state.received = false;
    state.receivedType = null;
    state.error = null;
    state.voltageError = null;
  }
}

export const { clearData } = dataSlice.actions;
export default dataSlice.reducer;