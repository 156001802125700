import { createSlice } from '@reduxjs/toolkit';
import { applyRoffset, getConfigField, setConfigField } from '../api';

const initialState = {
  loading: false,
  error: null,
  response: null,
  configFields: []
};

const configSlice = createSlice({
  name: 'config',
  initialState: initialState,
  reducers: {
    clearConfigs(state) {
      state.configFields = [];
    },
    // set the value of the input field
    setConfigFieldCurrent(state, action) {
      let index = state.configFields.findIndex(item => item.deviceEUI === action.payload.deviceEUI && item.name === action.payload.name);
      if (index >= 0) {
        state.configFields[index].desired = action.payload.value;
      } else {
        let field = {
          deviceEUI: action.payload.deviceEUI,
          desired: action.payload.value
        }
        state.configFields.push(field);
      }

      //state.configFields[action.payload.deviceEUI][action.payload.name].current = action.payload.value;
      state.error = null;
    },
    // update status
    setStatusFailed(state, action) {
      let index = state.configFields.findIndex(item => item.deviceEUI === action.payload.deviceEUI && item.name === action.payload.name);
      if (index >= 0) {
        if (state.configFields[index].status === "sent") {
          state.configFields[index].status = "failed";
        }
      }
    }
  },
  extraReducers: {
    [getConfigField.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
      if (!state.configFields) {
        state.configFields = [];
      }
    },
    [getConfigField.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getConfigField.fulfilled]: (state, action) => {
      state.loading = false;
      /*configFields is like this:
      [
        { 
          deviceEUI: "240ac4ffff0aaa20",
          name: "ctprim",
          desired: "100",
          reported: "100",
          current: "100"
        },
        {
          deviceEUI: "240ac4ffff0aaa20",
          name: "extloant"
          desired: "1",
          reported: "",
          current: "1",
          status: "sent"
        }
      ]
      */
      let index = state.configFields.findIndex(item => item.deviceEUI === action.meta.arg.deviceEUI && item.name === action.meta.arg.fieldName);
      if (index >= 0) {
         let field = {
          ...state.configFields[index],
          ...action.payload
        }
        if (field.status === "sent" && field.desired === field.reported) {
          field.status = "updated";
        }
        state.configFields[index] = field;
      } else {
        let field = {
          deviceEUI: action.meta.arg.deviceEUI,
          status: "",
          ...action.payload
        }
        state.configFields.push(field);
      }

      /*state.configFields[action.meta.arg.deviceEUI] = {
        ...state.configFields[action.meta.arg.deviceEUI],
        [action.payload.name]: {
          ...state.configFields[action.meta.arg.deviceEUI][action.payload.name],
          ...action.payload,
          current: action.payload.desired
        }
      }*/
    },    
    [setConfigField.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
      state.response = null;
    },
    [setConfigField.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [setConfigField.fulfilled]: (state, action) => {
      state.loading = false;
      state.response = action.payload;
      let index = state.configFields.findIndex(item => item.deviceEUI === action.meta.arg.deviceEUI && item.name === action.meta.arg.fieldName);
      if (index >= 0) {
        state.configFields[index] = {
          ...state.configFields[index],
          status: "sent"
        }
      } 
    },
    [applyRoffset.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
      state.response = null;
    },
    [applyRoffset.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [applyRoffset.fulfilled]: (state, action) => {
      state.loading = false;
      state.response = action.payload;
    },
  }
});

export const { clearConfigs, setConfigFieldCurrent, setStatusFailed } = configSlice.actions;

export default configSlice.reducer;