import { createAsyncThunk } from '@reduxjs/toolkit';
import authAPI from '../../scripts/authAPI';

export const login = createAsyncThunk(
  'auth/login',
  async (args) => {
      try {
          const response = await authAPI.getToken(args.username, args.password);
          
          return {
              token: response,
              username: args.username,
              password: args.password
          };
      } catch (err) {
          throw Error(err.message);
      }
  }
);