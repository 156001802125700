import {
  getDataServiceInstMsgUrl,
  getDataServiceUplinkMsgUrl,
  getDataServicePQMsgUrl,
} from "./urlHelper";
import axios from "axios";

const getOptions = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
    },
  };
};

const getFormattedValue = (part) => {
  if (part < 10) {
    return `0${part}`;
  }
  return `${part}`;
};

const getUTCString = (subtractSeconds) => {
  const dd = new Date(Date.now() - subtractSeconds * 1000);
  const formatted = `${dd.getUTCFullYear()}-${getFormattedValue(
    dd.getUTCMonth() + 1
  )}-${getFormattedValue(dd.getUTCDate())} ${getFormattedValue(
    dd.getUTCHours()
  )}:${getFormattedValue(dd.getUTCMinutes())}:${getFormattedValue(
    dd.getUTCSeconds()
  )}Z`;
  return formatted;
};

const dataAPI = {};

dataAPI.getPQMsgData = async (deviceEUI, sinceSeconds, token) => {
  const dataApi = getDataServicePQMsgUrl();
  const starttime = getUTCString(sinceSeconds);
  const url = `${dataApi}?DEVICEEUI=eq.${deviceEUI}&TIMESTAMP=gte.${encodeURIComponent(
    starttime
  )}&order=TIMESTAMP.desc`;
  let options = getOptions(token);
  const response = await axios.get(url, options);
  return response.data;
};

dataAPI.getInstMsgData = async (deviceEUI, sinceSeconds, token) => {
  const dataApi = getDataServiceInstMsgUrl();
  const starttime = getUTCString(sinceSeconds);
  const url = `${dataApi}?DEVICEEUI=eq.${deviceEUI}&TIMESTAMP=gte.${encodeURIComponent(
    starttime
  )}&order=TIMESTAMP.desc`;
  let options = getOptions(token);
  const response = await axios.get(url, options);
  return response.data;
};

dataAPI.getUplinkMsgData = async (deviceEUI, sinceSeconds, token) => {
  const dataApi = getDataServiceUplinkMsgUrl();
  const starttime = getUTCString(sinceSeconds);
  const url = `${dataApi}?DEVICEEUI=eq.${deviceEUI}&TIMESTAMP=gte.${encodeURIComponent(
    starttime
  )}&order=TIMESTAMP.desc`;
  let options = getOptions(token);
  const response = await axios.get(url, options);
  return response.data;
};

export default dataAPI;
