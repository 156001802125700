import { createAsyncThunk } from "@reduxjs/toolkit";

// import { setToken } from "../auth/slices";
// import authAPI from "../../scripts/authAPI";
import connectionAPI from "../../scripts/connectionAPI";
import keycloak from "../../keycloak";

const getToken = async (thunkAPI) => {
  if (keycloak) {
    return keycloak.token;
  }

  return null;
  /*
    let state = thunkAPI.getState();
    const refreshToken = await authAPI.refreshToken(state.auth.token, state.auth.username, state.auth.password);
    thunkAPI.dispatch(setToken(refreshToken));
  
    return refreshToken;
    */
};

export const getConnections = createAsyncThunk(
  "connection/getConnections",
  async (_, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await connectionAPI.getConnections(token);
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const getConnectionById = createAsyncThunk(
  "connection/getConnectionById",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await connectionAPI.getConnectionById(args.id, token);
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const getDevice = createAsyncThunk(
  "connection/getDevice",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await connectionAPI.getDeviceBySerialNumber(
        args.serialNumber,
        token
      );
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const createConnection = createAsyncThunk(
  "connection/createConnection",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await connectionAPI.createConnection(
        args.connection,
        args.oldIdentifier,
        token
      );
      thunkAPI.dispatch(getConnections());
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const updateConnection = createAsyncThunk(
  "connection/updateConnection",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await connectionAPI.updateConnection(
        args.identifier,
        args.connection,
        token
      );
      thunkAPI.dispatch(getConnections());

      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const getImage = createAsyncThunk(
  "connection/getImage",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await connectionAPI.getImage(args.identifier, token);
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const createImage = createAsyncThunk(
  "connection/createImage",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await connectionAPI.createImage(args.image, token);
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const deleteImage = createAsyncThunk(
  "connection/deleteImage",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await connectionAPI.deleteImage(args.identifier, token);
      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);

export const updateJob = createAsyncThunk(
  "connection/updateJob",
  async (args, thunkAPI) => {
    try {
      const token = await getToken(thunkAPI);
      const response = await connectionAPI.updateJob(args.id, args.job, token);
      thunkAPI.dispatch(getConnections({ token: args.token }));

      return response;
    } catch (err) {
      throw new Error(
        err.response && err.response.data ? err.response.data : err.message
      );
    }
  }
);
