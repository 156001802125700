import axios from 'axios';

const TIMESCALE_INST_VIEW = "INST";
const TIMESCALE_PQ_VIEW = "PQ";
const TIMESCALE_UPLINK_VIEW = "UPLINK";

export function getTenant() {
    //const host = window.location.hostname;
    let tenant = process.env.REACT_APP_TENANT;
    if (tenant === undefined || tenant === null) {
      console.log('TENANT not defined, using default one');
     
      //old code, just in case, as a backup
      const host = window.location.hostname;
      if (host === "localhost") {
        return "stagepower";
      }
      tenant = window.location.host.split('.')[1];
      if (tenant === undefined || tenant === null) { //may 168 if accessing local server from mobile
        return "stagepower";
      }
    }
    return tenant;
}

export async function getVersion() {
  let env_url = process.env.REACT_APP_VERSION_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    const url = `https://installer.${tenant}.powerpilot.nz/version`;
    const response = await axios.get(url);
    return response.data;
  }
  else
  {
    const response = await axios.get(env_url);
    return response.data;
  }
}

export function getConnectionServiceUrl() {
  let env_url = process.env.REACT_APP_CONNECTION_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    return `https://connection.${tenant}.powerpilot.nz`;
  }
  else
  {
    console.log("getConnectionServiceUrl() :" + env_url);
    return env_url;
  }
}

export function getConfigServiceUrl() {
  let env_url = process.env.REACT_APP_CONFIG_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    return `https://config.${tenant}.powerpilot.nz`;
  }
  else
  {
    console.log("getConfigServiceUrl() :" + env_url);
    return env_url;
  }
}

export function getFunctionServiceUrl() {
  let env_url = process.env.REACT_APP_FUNCTION_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    return `https://function.${tenant}.powerpilot.nz`;
  }
  else
  {
    console.log("getFunctionServiceUrl() :" + env_url);
    return env_url;
  }
}

export function getAuthServerUrl() {
  let env_url = process.env.REACT_APP_AUTHSERVER_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    return `https://auth.${tenant}.powerpilot.nz/auth`;
  }
  else
  {
    console.log("getAuthServerUrl() :" + env_url);
    return env_url;
  }
}

export function getAuthServiceUrl() {
  let env_url = process.env.REACT_APP_AUTH_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    return `https://auth.${tenant}.powerpilot.nz`;
  }
  else
  {
    console.log("getAuthServiceUrl() :" + env_url);
    return env_url;
  }
}

export function getMqttHost() {
  let env_url = process.env.REACT_APP_MQTT_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    return `mqttcs.${tenant}.powerpilot.nz`;
  }
  else
  {
    return env_url;
  }
}

export function getKeycloakRealm() {
  const tenant = getTenant();
  console.log("getKeycloakRealm() :" + tenant);
  return tenant;
}

//data api urls
export function getDataServiceInstMsgUrl() {
  let env_url = process.env.REACT_APP_DATA_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    return `https://data.${tenant}.powerpilot.nz/${TIMESCALE_INST_VIEW}`;
  }
  else
  {
    return `${env_url}/${TIMESCALE_INST_VIEW}`;
  }
}

export function getDataServiceUplinkMsgUrl() {
  let env_url = process.env.REACT_APP_DATA_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    return `https://data.${tenant}.powerpilot.nz/${TIMESCALE_UPLINK_VIEW}`;
  }
  else
  {
    return `${env_url}/${TIMESCALE_UPLINK_VIEW}`;
  }
}

export function getDataServicePQMsgUrl() {
  let env_url = process.env.REACT_APP_DATA_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    return `https://data.${tenant}.powerpilot.nz/${TIMESCALE_PQ_VIEW}`;
  }
  else
  {
    return `${env_url}/${TIMESCALE_PQ_VIEW}`;
  }
}

//realtime urls
export function getRealtimeGetUrl(topic, deviceeui, phase) {
  const tenant = getTenant();
  let env_url = process.env.REACT_APP_REALTIME_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    let url = `https://realtime.${tenant}.powerpilot.nz/api/table/${tenant}_${topic}/${deviceeui}`;
    
    if (phase) {
      url += `/${phase}`;
    }
    return url;
  }
  else
  {
    let url = `${env_url}/api/table/${tenant}_${topic}/${deviceeui}`;
    if (phase) {
      url += `/${phase}`;
    }
    return url;
  }
}

export function getRealtimeSocketIOUrl(topic, deviceeui, phase) {
  const tenant = getTenant();
  let env_url = process.env.REACT_APP_REALTIME_SERVICE_URL;
  if (env_url === undefined || env_url === null || env_url === '')
  {
    const tenant = getTenant();
    let url = `https://realtime.${tenant}.powerpilot.nz/api/${tenant}_${topic}/${deviceeui}`;
  
    if (phase) {
      url += `/${phase}`;
    }
    return url;
  }
  else
  {
    let url = `${env_url}/api/${tenant}_${topic}/${deviceeui}`;
    if (phase) {
      url += `/${phase}`;
    }
    return url;
  }
}
