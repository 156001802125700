import { createSlice } from '@reduxjs/toolkit';

import { callDeviceFunction } from '../api';

const initialState = {
  loading: false,
  error: null,
  response: null
};

const functionSlice = createSlice({
  name: 'function',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [callDeviceFunction.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    },
    [callDeviceFunction.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [callDeviceFunction.fulfilled]: (state, action) => {
      state.loading = false;
      state.response = action.payload;
    },
  }
});

//export const {  } = functionSlice.actions;
export default functionSlice.reducer;